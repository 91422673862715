import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/views/Layout'),
    redirect: "/admin/home",
    children: [
      {
        path: "/admin/home",
        name: "admin_home",
        component: () => import('@/views/home/Index'),
        meta: { crumb: ["主页"] }
      },
      {
        path: "/admin/setting/system_role/index",
        name: "admin_setting_system_role_index",
        component: () => import("@/views/setting/Permission/SystemRole"),
        meta: { crumb: ["设置", "管理权限", "角色管理"] }
      },
      {
        path: "/admin/Personalizar",
        name: "admin_Personalizar",
        component: () => import('@/views/preson/index'),
        meta: { crumb: ["个人中心"] }
      },
      {
        path: "/admin/setting/system_menus/index",
        name: "admin_setting_system_menus",
        component: () => import('@/views/setting/Permission/Rule'),
        meta: { crumb: ["设置", "权限管理", "权限规则"] }
      },
      {
        path: "/admin/setting/system_position/index",
        name: "admin_setting_system_position_index",
        component: () => import('@/views/setting/Permission/Department'),
        meta: { crumb: ["设置", "权限管理", "部门管理"] }
      },
      {
        path: "/admin/setting/agreement",
        name: "admin-setting-agreement",
        component: () => import('@/views/setting/Agreement'),
        meta: { crumb: ["设置", "协议设置"] }
      },
      {
        path: "/admin/product/product_list",
        name: "admin_product_product_list",
        component: () => import("@/views/commodity/Manager"),
        meta: { crumb: ["商品", "商品管理"] },
      },
      {
        path: "/admin/store/coupon",
        name: "admin-store-coupon",
        component: () => import("@/views/commodity/Coupon"),
        meta: { crumb: ["商品", "优惠券"] },
      },
      {
        path: "/marketing/lottery",
        name: "marketing-lottery",
        component: () => import("@/views/commodity/LuckDraw"),
        meta: { crumb: ["商品", "九宫格抽奖"] },
      },
      {
        path: "/marketing/lottery/detail",
        name: "marketing-lottery-detail",
        component: () => import("@/views/commodity/LuckDrawDetail"),
        meta: { crumb: ["商品", "九宫格抽奖", "添加活动"] },
      },
      {
        path: "/admin/product/add_product",
        name: "admin_product_add_product",
        component: () => import("@/views/commodity/AddProduct"),
        meta: { crumb: ["商品", "商品管理", "添加商品"] }
      },
      {
        path: "admin/contract/contract_template/inde",
        name: "admin_contract_contract_template_inde",
        component: () => import('@/views/contract/Contract'),
        meta: { crumb: ["合同", "合同模板"] }
      },
      {
        path: "/admin/contract/list",
        name: "admin-contract-list",
        component: () => import('@/views/contract/ContractManage'),
        meta: { crumb: ["合同", "合同管理"] }
      },
      {
        path: "/admin/product/product_classify",
        name: "admin_product_product_classify",
        component: () => import("@/views/commodity/ProductClass"),
        meta: { crumb: ["商品", "商品管理", "商品分类"] }
      },
      {
        path: "/admin/order/list",
        name: "admin_order_list",
        component: () => import("@/views/orders/Manage"),
        meta: { crumb: ["订单", "订单管理"] }
      },
      {
        path: "/admin/trade_order/list",
        name: "admin_trade_order_list",
        component: () => import("@/views/orders/BillManage"),
        meta: { crumb: ["订单", "账单管理"] }
      },
      {
        path: "/admin/order/user_index",
        name: "admin-order-user_index",
        component: () => import("@/views/orders/UserManager"),
        meta: { crumb: ["订单", "客户管理"] }
      },
      {
        path: "/approval/trade/list",
        name: "approval_trade_list",
        component: () => import("@/views/audit/BillAudit"),
        meta: { crumb: ["审核", "账单审核"] }
      },
      {
        path: "/approval/list",
        name: "approval_list",
        component: () => import("@/views/audit/OrderAudit"),
        meta: { crumb: ["审核", "订单审核"] }
      },
      {
        path: "/admin/system/config/system_config_tab/index",
        name: "admin_system_config_system_config_tab_index",
        component: () => import('@/views/maintain/development/ConfigClass'),
        meta: { crumb: ["维护", "开发配置", "配置分类"] }
      },
      {
        path: "/admin/system/config/system_config_tab/list/1",
        name: "admin_system_config_system_config_tab_list_1",
        component: () => import("@/views/maintain/development/ConfigList"),
        meta: { crumb: ["维护", "开发配置", "配置列表"] }
      },
      {
        path: "/admin/system/maintain/system_log/index",
        name: "admin-system-maintain-system_log-index",
        component: () => import("@/views/maintain/security/SystemLog"),
        meta: { crumb: ["维护", "安全维护", "系统日志"] }
      },
      {
        path: "/admin/user/list",
        name: "admin_user_list",
        component: () => import("@/views/user/UserManage"),
        meta: { crumb: ["用户", "用户管理"] }
      },
      {
        path: "/admin/setting/system_admin/index",
        name: "admin_setting_system_admin_index",
        component: () => import("@/views/user/StaffManage"),
        meta: { crumb: ["用户", "员工管理"] }
      },
      {
        path: "/admin/store/store_video/index",
        name: "admin-store-store_video-index",
        component: () => import("@/views/video/VideoManage"),
        meta: { crumb: ["视频", "视频管理"] }
      },
      {
        path: "/admin/store/video_category/index",
        name: "admin-store-video_category-index",
        component: () => import("@/views/video/VideoClass"),
        meta: { crumb: ["视频", "课程班级"] }
      },
      {
        path: "/admin/store/video_category/add_curriculum",
        name: "admin-store-video_category-add_curriculum",
        component: () => import("@/views/video/AddCurriculum"),
        meta: { crumb: ["视频", "视频专栏", "新增课程"] }
      },
      {
        path: "/admin/store/video_category/course_detail",
        name: "admin-store-video_category-course_detail",
        component: () => import("@/views/video/CourseDetail"),
        meta: { crumb: ["视频", "视频专栏", "课程详情"] }
      },
      {
        path: "/admin/store/store_video/AddVideo",
        name: "admin-store-store_video-AddVideo",
        component: () => import("@/views/video/AddVideo"),
        meta: { crumb: ["视频", "视频管理", "新增视频"] }
      },
      {
        path: "/admin/store/store_video/video_detail",
        name: "admin-store-store_video-video_detail",
        component: () => import("@/views/video/VideoDetail"),
        meta: { crumb: ["视频", "视频管理", "视频详情"] }
      },
      {
        path: "/admin/store/store_video/add_class",
        name: "admin-store-store_video-add_class",
        component: () => import("@/views/video/AddClass"),
        meta: { crumb: ["视频", "视频管理", "新增班级"] }
      },
      {
        path: "/admin/store/video_category/class_detail",
        name: "admin-store-video_category-class_detail",
        component: () => import("@/views/video/DetailClass"),
        meta: { crumb: ["视频", "视频管理", "班级详情"] }
      },
      {
        path: "/admin/store/video_category/add_student",
        name: "admin-store-video_category-add_student",
        component: () => import("@/views/video/AddStudent"),
        meta: { crumb: ["视频", "班级详情", "添加学员"] }
      },
      {
        path: "/admin/cms/cms",
        name: "admin-cms-cms",
        component: () => import("@/views/article/Manage"),
        meta: { crumb: ["文章", "文章管理"] }
      },
      {
        path: "/admin/cms/category",
        name: "admin-cms-category",
        component: () => import("@/views/article/Category"),
        meta: { crumb: ["文章", "文章分类"] }
      },
      {
        path: "/admin/cms/add_article",
        name: "admin-cms-add_article",
        component: () => import("@/views/article/AddArticle"),
        meta: { crumb: ["文章", "文章管理", "添加文章"] }
      },
      {
        path: "/sms/sms",
        name: "sms-sms",
        component: () => import("@/views/setting/MsgManage/ShortMessage"),
        meta: { crumb: ["设置", "消息管理", "短信管理"] }
      },
      {
        path: "/wechart/list",
        name: "wechart-list",
        component: () => import("@/views/setting/MsgManage/TemplateManage"),
        meta: { crumb: ["设置", "消息管理", "模板管理"] }
      },
      {
        path: "/finance/down",
        name: "finance-down",
        component: () => import("@/views/finance/FinanceManage"),
        meta: { crumb: ["财务", "财务管理"] }
      },
      {
        path: "/living/index",
        name: "living-index",
        component: () => import("@/views/livingcode/Living"),
        meta: { crumb: ["对接", "对接列表"] }
      },
      {
        path: "/code/index",
        name: "code-index",
        component: () => import("@/views/livingcode/Code"),
        meta: { crumb: ["对接", "二维码列表"] }
      },
      {
        path: "/teacher/index",
        name: "teacher-index",
        component: () => import("@/views/livingcode/teacher"),
        meta: { crumb: ["对接", "对接老师"] }
      },
      {
        path: "/feedback/myindex",
        name: "feedback-myindex",
        component: () => import("@/views/workOrder/MyWork"),
        meta: { crumb: ["工单", "我的工单"] }
      },
      {
        path: "/feedback/index",
        name: "feedback-index",
        component: () => import("@/views/workOrder/WorkManage"),
        meta: { crumb: ["工单", "工单管理"] }
      },
      {
        path: "/complaint/index",
        name: "complaint-index",
        component: () => import("@/views/workOrder/complaint/index"),
        meta: { crumb: ["工单", "投诉管理"] }
      },
      {
        path: "/feedback/index/detail",
        name: "feedback-index-detail",
        component: () => import("@/views/workOrder/WorkDetail"),
        meta: { crumb: ["工单", "工单详情"] }
      },
      {
        path: "/admin/wework_admin/index",
        name: "admin-wework_admin-index",
        component: () => import("@/views/code/ContactMe"),
        meta: { crumb: ["活码", "联系我"] }
      },
      {
        path: "/admin/wework_admin/detail",
        name: "admin-wework_admin-detail",
        component: () => import("@/views/code/ContactDetail"),
        meta: { crumb: ["活码", "联系我", "详情"] }
      },
      {
        path: "/admin/wework_admin/add",
        name: "admin-wework_admin-add",
        component: () => import("@/views/code/AddContact"),
        meta: { crumb: ["活码", "新建联系方式"] }
      },
      {
        path: "/work_user/index",
        name: "work_user-index",
        component: () => import("@/views/code/Resources"),
        meta: { crumb: ["活码", "资源中心"] }
      },
      {
        path: "/finance/open_clues",
        name: "openClues",
        component: () => import("@/views/financeClues/openClues"),
        meta: { crumb: ["客户中心", "公海线索"] }
      },
      {
        path: "/finance/sell_clues",
        name: "sellClues",
        component: () => import("@/views/financeClues/sellClues"),
        meta: { crumb: ["客户中心", "客户管理"] }
      },
      {
        path: "/clue/admins",
        name: "clue-admins",
        component: () => import("@/views/financeClues/staffManage"),
        meta: { crumb: ["客户中心", "员工管理"] }
      },
      {
        path: "/page/merchant",
        name: "merchant",
        component: () => import("@/views/outerChain/configCenter/merchant"),
        meta: { crumb: ["外链", "配置中心", "商户配置"] }
      },
      {
        path: "/page",
        name: "page",
        component: () => import("@/views/outerChain/configCenter/chainConfig"),
        meta: { crumb: ["外链", "外链管理", "外链配置"] }
      },
      {
        path: "/agent/index",
        name: "agent-index",
        component: () => import("@/views/outerChain/configCenter/proxyConfig"),
        meta: { crumb: ["外链", "配置中心", "代理配置"] }
      },
      {
        path: "/data/user",
        name: "data-user",
        component: () => import("@/views/outerChain/dataCenter/userData"),
        meta: { crumb: ["外链", "数据中心", "用户数据"] }
      },
      {
        path: "/data/count",
        name: "data-count",
        component: () => import("@/views/outerChain/dataCenter/dataCount"),
        meta: { crumb: ["外链", "数据中心", "数据统计"] }
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  },
  {
    path: "/admin/regedit",
    name: "register",
    component: () => import("@/views/register")
  },
  {
    path: "/error",
    name: "404",
    component: () => import("@/views/404")
  }
]

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/admin/",
})

export default router
