export default {
    state: {
        taglist: [{
            path: "/admin/home",
            title: "主页"
        }]
    },
    mutations: {
        addTaglist(state, payload) {
            const result = state.taglist.findIndex(item => {
                return item.path == payload.path
            })
            if (result == -1) {
                state.taglist.push(payload)
            }
        },
        removeTaglist(state, payload) {
            const result = state.taglist.findIndex(item => {
                return item.path == payload
            })
            state.taglist.splice(result, 1)
        },
        setTaglist(state, payload) {
            state.taglist = payload
        }
    }
}