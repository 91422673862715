import Vue from 'vue'
import Vuex from 'vuex'
import Tags from './Tags'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menus: [],
    menuShow: false,
  },
  getters: {
  },
  mutations: {
    setMenus(state, payload) {
      state.menus = payload
    },
    setMenusShow(state) {
      state.menuShow = !state.menuShow
    },
  },
  actions: {
  },
  modules: {
    Tags
  }
})
